/* eslint-disable import/extensions */
import { bool, number, object, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import {LazyCollection,LazyLoadImages, replaceAllStoriesInCollection, WithPreview } from "@quintype/components";

import { getCollectionTemplate } from "../get-collection-template";
import { Perheuutiset } from "../atoms/perheuutiset-banner";
//import Suosituimmat from "../story-templates/suosituimmat";
import EditorialCategory from "../story-templates/editorial-category";
import FromReaderCategory from "../story-templates/fromreader-category";
import RightNow from "../story-templates/right-now";
import "./home.m.css";
import { EtusivuSuurtaulu } from "../atoms/suurtaulu-frontpage";
import ColumnCategory from "../story-templates/column-category";
import useDOMrender from "../../hook/useDOMRender";
import Uusimmat from "../story-templates/uusimmat";

const Home = ({ data, reloadTimeInMinutes = 15, shouldReload = false }) => {
  const stories = get(data, ["collection"], ["items"]);
  const { mostPopular, rightNow } = data;
  const [check, anyhow] = useState(true);
  const onclick = () => anyhow(false);
  const[domRendered] = useDOMrender(data);
  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;

    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);
  return (
    <div styleName="top-container">
    <div styleName="container">
      <div styleName="main">
        <div styleName="ad-container">
          {/* PARAATI mobiili*/}
          <div styleName="mob-ad">
            {domRendered&&<div id="sas_63333"></div>}
          </div>
          {/* PARAATI deskari ja tabletti*/}
          <div styleName="dsktop-ad">
            {domRendered&&<div id="sas_63219"></div>}
          </div>
        </div>
          <EtusivuSuurtaulu />
      </div>
      <div styleName="lazy-pop">
        <div styleName="lazy-load">
         {/*<div styleName="right-now">
            <RightNow rightNow={rightNow} />
          </div>*/}
          {/*<div styleName="popular-grid-hide">*/}
          {/*<div styleName="sidebar-ad-hide" id="sas_76185"></div>*/}
            {/*<div>*/}
            {/*<SideBar mostPopular={mostPopular} />*/}
            {/*</div>
          </div>*/}
          <LazyLoadImages>
            <LazyCollection collection={data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={22} />
          </LazyLoadImages>
        </div>
        <div styleName="popular">
          <div styleName="sidebar">
            <div styleName="popular-grid">
              {/* Pystyparaati deskari*/}
              <div styleName="pystyparaati">
                {domRendered&&<div id="sas_63222"></div>}
              </div>
              {/*<div styleName="sidebar-ad-placeholder">Sidebar Ad 300 * 600(Pystyparaati)</div>*/}
              {/*<SideBar mostPopular={mostPopular} />*/}
              <Perheuutiset />
              {/*<Suosituimmat />*/}
              <Uusimmat />
              <EditorialCategory />
              <ColumnCategory />
              <FromReaderCategory />
              {/*<div styleName="sidebar-ad-margin" id="sas_76185"></div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

Home.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const HomePage = connect(mapStateToProps, () => ({}))(Home);

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
