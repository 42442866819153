
import React from "react";
import { func, array, bool } from "prop-types";
import { Link } from "@quintype/components";
import TimeStamp from "../../atoms/time-stamp";
import StarShowHide from "../../atoms/star-show-hide";
import CardImageType8 from "../card-image-type-8";
import PT from 'prop-types';
import "./section-show-more-stories.m.css";
const SectionShowMoreStories = ({ stories, startIndex, itemsToLoad }) => {
  return stories.slice(startIndex, itemsToLoad).map((story, index) => {
    const storyObj = story.story || story;
    /* getting url right regarding different papers*/
    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }
    let findParams = findByKey("domain-slug", "jurvan-sanomat") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)
    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts + 1);
    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]) {
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
    }
    return (
      <React.Fragment key={index}>
        {story["hero-image-s3-key"] !== null ?
          <div className="item" key={story.id}>
            <CardImageType8 story={story} showSubHeadline={false} />
          </div> :
          <div className="main-headline item" styleName={story["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
            <Link href={
              positionOfPaper !== -1 ? (
                storyObj.sections[positionOfPaper] ? (
                  `/${finalUrl}/${storySlug}`
                ) : (
                  `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
                )
              ) : (
                `/${storyObj.slug}`
              )
            }>
              <div styleName="wrapper-inside" className="wrapper-inside">
                <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                  <span styleName="span-newest">{story.metadata && story.metadata["pääaihe"] && `${story.metadata["pääaihe"]} | `}</span>
                  {story.headline}
                </h2>
                <div styleName="section-time-wrapper">
                  <div className="time-stamp-layout1">
                    <TimeStamp timeStamp={story["published-at"]} />
                  </div>
                  <StarShowHide accessType={story.access} />
                </div>
              </div>
            </Link>
          </div>
        }
      </React.Fragment>
    );
  });
};
export default SectionShowMoreStories;
SectionShowMoreStories.propTypes = {
  onLoadMore: func,
  stories: array,
  noMoreStories: bool,
  itemsToLoad: PT.number
};
