import React, { useState } from "react";
import DOMPurify from "dompurify";
import useStorage from "../../../hook/useStorage";
import useLinks from "../../../hook/useLinks";
import { Suurtaulu } from "../../atoms/suurtaulu";
import "./tulo-login.m.css";

const TuloLogin = () => {

  const [checkUser, activeProducts, whiteListed] = useStorage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [links] = useLinks("createaccount");
  const [link] = useLinks("forgotpassword");

  const handleEmailChange = e => {
    const { value } = e.target;
    const emailValue = DOMPurify.sanitize(value);
    setEmail(emailValue);
  };

  const handlePasswordChange = e => {
    const { value } = e.target;
    const passwordValue = DOMPurify.sanitize(value);
    setPassword(passwordValue);
  };

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
    setPasswordIcon(!passwordIcon);
  };

  return (
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            {/* PARAATI mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_63333"></div>
            </div>
            {/* PARAATI deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_63219"></div>
            </div>
          </div>
        </div>
        <div styleName="lazy-pop">
          <div styleName="digilehti-content">
        <div>
          {checkUser && activeProducts && (
            <a href="https://digilehti.jurvansanomat.fi/" target="_blank" rel="noreferrer">
              <figure>
                <img src="https://www.e-pages.dk/jurvansanomat/teasers/custom_300.jpg" border="0" width="300" />
              </figure>
            </a>
          )}
          {/** This condition needs to be checked because it contains both true and false values of checkUser! */}
          {/** If this does not apply to logged in or not logged user, then we can remove checkUser */}
          {((checkUser && !activeProducts) || !checkUser) && (
            <a target="_self">
              <figure>
                <img src="https://www.e-pages.dk/jurvansanomat/teasers/custom_300.jpg" border="0" width="300" />
              </figure>
            </a>
          )}
        </div>

        <div>
          <div id="yleisolle">
            <h2>Digitaaliset sisällöt!</h2>
            <p>
              Jurvan Sanomien tilaus sisältää näköislehden ja verkkosivun tilaajasisällöt. Lukeaksesi näköislehteä tai
              verkon tilaajasisältöjä sinulla tulee olla voimassa oleva tilaus sekä aktiivinen I-Mediatili.
            </p>

            {((checkUser && activeProducts) || whiteListed) && (
              <div>
                <div styleName="digilehti-cta">
                  <a href="https://digilehti.jurvansanomat.fi/" target="_blank" styleName="read" rel="noreferrer">
                    Lue
                  </a>
                </div><br />
                <p>Jos päivän näköislehti ei avaudu, kokeile kirjautua ulos ja
                      sen jälkeen kirjaudu uudestaan sisään.</p>
              </div>
            )}
            {checkUser && !activeProducts && (
              <div styleName="digilehti-cta">
                <a
                  href="https://asiakaspalvelu.i-mediat.fi/jurvansanomat/tilaa"
                  target="_blank"
                  styleName="buy"
                  rel="noreferrer"
                >
                  Tilaa
                </a>
              </div>
            )}
            {!checkUser && (
              <>
                <p>Lukeaksesi näköislehteä sinulla tulee olla voimassa oleva I-Mediatili.</p>
                <div styleName="digilehti-cta">
                  <a href={links} styleName="create-account">
                    Luo tili
                  </a>
                  <a
                    href="https://asiakaspalvelu.i-mediat.fi/jurvansanomat/tilaa"
                    target="_blank"
                    styleName="buy"
                    rel="noreferrer"
                  >
                    Tilaa
                  </a>
                </div>
              </>
            )}
          </div>
          {!checkUser && (
            <React.Fragment>
              <div styleName="login-form">
                <h3>Kirjaudu sisään</h3>
                <form action="/tulo/authenticate" method="post">
                  <div>
                    <div>
                      <label>Käyttäjätunnus</label>
                      <input
                        styleName="digipaper-login"
                        value={email}
                        type="email"
                        name="email"
                        onChange={e => handleEmailChange(e)}
                      />
                    </div>
                    <div>
                      <label>Salasana</label>
                      <div styleName="passWd">
                      <input
                        value={password}
                        styleName="digipaper-login-passWd"
                        //type="password"
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        onChange={e => handlePasswordChange(e)}
                      />
                      <a aria-label="Näytä salasana" styleName="show-passWd" role="button" onClick={togglePassword}><i styleName="eye-icon" className={`${passwordIcon ? "ri-eye-line" : "ri-eye-off-line"}`}></i></a>
                    </div>
                    </div>

                    <a href={link} styleName="forgot-link">
                      Unohtuiko salasana?
                    </a>

                    <div>
                      <input styleName="login-submit" type="submit" value="Kirjaudu sisään" />
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          )}
          </div>
        </div>
        <div styleName="side">
          {/* Pystyparaati deskari*/}
          <div styleName="pystyparaati">
            <div id="sas_63222"></div>
          </div>
          <div styleName="digipaper-sideinfo">
            <h3>Lue lehti missä ja milloin tahansa</h3>
            <p>
              Jurvan Sanomien näköislehti on painetun lehden digitaalinen versio, joka julkaistaan lehden verkkosivuilla.
              Näköislehti on luettavissa nyt julkaisupäivää edeltävänä iltana klo 20:00 alkaen tietokoneella, tabletilla
              sekä älypuhelimella. Lukeaksesi näköislehteä tarvitset voimassa olevan Jurvan Sanomien tilauksen sekä internetyhteyden laitteeseesi.
            </p>
            <h3>Löydä vanhat lehdet arkistosta</h3>
            <p>
              Näköislehden arkistosta löydät Jurvan Sanomat 15.8.2012 lähtien. Arkisto on käytettävissä, kun luet näköislehteä
              tietokoneella. Arkiston löydät näköislehden oikeasta laidasta Kalenteri-kuvakkeen takaa. Voit myös hakea
              sanahaulla juttuja sekä päivän lehdestä että arkistoiduista lehdistä.
            </p>
            <h3>Verkon tilaajasisällöt - saat enemmän ja aiemmin kuin muut</h3>
            <p>
              Verkkosivultamme löydät tuoreimmat uutiset sekä muuta sisältöä, joka on vain tilaajien luettavissa. Verkon
              tilaajasisällöt on merkitty tähdellä.
            </p>
            <p>Lisätietoja</p>
            <ul>
              <li>
                <a
                  href="https://asiakaspalvelu.i-mediat.fi/jurvansanomat/asiakastuki/#digikayttoon"
                  target="_blank"
                  rel="noreferrer"
                >
                  Asiakasnumeron haku
                </a>
              </li>
              {/* <li><a href="">Päivän lukuoikeus</a></li> */}
            </ul>
          </div>
          {/* Pystyparaati mobiili ja tabletti*/}
          <div styleName="mob-ad-pystyparaati">
            <div id="sas_63337"></div>
          </div>
        </div>
      </div>
    </div>
    <Suurtaulu/>
  </div>
  );
};
export { TuloLogin };
