import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import PT from "prop-types";
import assetify from "@quintype/framework/assetify";
import Home from "../../basic/images/home-solid.svg";
import "./breadcrumb.m.css";

const getSlug = (collection, config, storySectionId) => {
  let slug = {};
  let parentSlug = {};
  let childSlug = {};
  let collectionSlug = {};
  const sections = get(config, ["sections"], []);
  const metadata = Object.keys(get(collection, ["metadata"], {}));
  const isSection = metadata.indexOf("section") !== -1 || storySectionId;
  if (isSection) {
    const sectionId = (collection && collection.metadata.section[0].id) || storySectionId;
    const section = sections.find(o => o.id === sectionId);
    if (section && section["parent-id"] !== null) {
      const parentSection = sections.find(o => o.id === section["parent-id"]);

      if (parentSection) {
        childSlug = parentSection || {};
        const childSections = sections.find(o => o.id === parentSection["parent-id"]);
        slug = section;
        parentSlug = childSections && childSections;
      }
    }
    if (section && section["parent-id"] === null) {
      slug = section;
    }
  } else {
    collectionSlug = collection;
  }
  return {
    slug: slug,
    childSlug: childSlug,
    parentSlug: parentSlug,
    collectionSlug: collectionSlug
  };
};

const Breadcrumb = ({ collection, sectionId, story = {}, config, storySlug, slugName }) => {
  const { slug, childSlug, parentSlug, collectionSlug } = getSlug(collection, config, sectionId);
  return (
    <div styleName="wrapper">
      <ol styleName="inner-wrapper" className="crumb-section">
        <li styleName="item">
           <a href="/"><img src={`${assetify(Home)}`} alt="home" width="25" /></a>
        </li>
        {parentSlug && Object.keys(parentSlug).length > 0 && (
          <li styleName="item">
            <a href={parentSlug["section-url"]}>{parentSlug["slug"].replace(/-/g, " ")}</a>
          </li>
        )}
        {childSlug && Object.keys(childSlug).length > 0 && (
          <li styleName="item">
            <a href={childSlug["section-url"]}>{childSlug["slug"].replace(/-/g, " ")}</a>
          </li>
        )}
        {!sectionId && slug && Object.keys(slug).length > 0 && (
          <li styleName="item">
            <h1>{slug["slug"].replace(/-/g, " ")}</h1>
          </li>
        )}
        {sectionId && slug && Object.keys(slug).length > 0 && (
          <li styleName="item">
            <a href={slug["section-url"]}>{slug["slug"].replace(/-/g, " ")}</a>
          </li>
        )}
        {/*sectionId && (
          <li styleName="item">
            {storySlug
              .split("/")
              .pop()
              .replace(/-/g, " ")}
          </li>
            )*/}
        {collectionSlug && Object.keys(collectionSlug).length > 0 && (
          <li styleName="item">
            <h1>{collectionSlug.name}</h1>
          </li>
        )}
        {slugName && (
          <li styleName="item">
            <h1>{slugName}</h1>
          </li>
        )}
      </ol>
    </div>
  );
};

Breadcrumb.propTypes = {
  collection: PT.object,
  sectionId: PT.number,
  story: PT.object,
  config: PT.object,
  storySlug: PT.string,
  slugName: PT.string
};

function mapStateToProps(state) {
  return {
    config: get(state, ["qt", "config"], {})
  };
}

export default connect(mapStateToProps, () => ({}))(Breadcrumb);
