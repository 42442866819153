import React, { useState } from "react";
import { array, string } from "prop-types";

import { Link } from "@quintype/components";

import TimeStamp from "../../atoms/time-stamp";
import { ShowMoreBtn } from "../../atoms/show-more-btn";
import StarShowHide from "../../atoms/star-show-hide";

import "./newest-category.m.css";

const NewestCategory = ({ newestCategory = [], id = "" }) => {
  const [loadMore, setLoadMore]= useState(5);
  const[isLogged, setIsLogged] = useState(false);
  const handleLoadMore = ()=> {
    setLoadMore(loadMore+5);
  }

  if (newestCategory.length < 1) {
    return null;
  }
  return (
    <React.Fragment>
      {/*<div styleName="sidebar-ad-margin" id="sas_76185"></div>*/}
      <div styleName="sidebar-global">
     <h2 className="top-headline" styleName="top-headline">UUSIMMAT</h2>
      <div>
      {newestCategory
        .slice(0, loadMore)
        .filter(story => story.id !== id)
        .map((item, index) => {
          const accessType = item.story.access;
          //console.log("itemmm", item)
          const paaaihe = item.story.metadata["pääaihe"];
          return (
            <React.Fragment key={index}>
              <Link href={`/${item.story.slug}`} styleName="wrapper" className="wrapper-newest-category" key={index}>
                {/*<HeroImage story={item.story} />*/}
                <h2 styleName="headline" className="main-headline">
                  <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
                {item.story.headline}
                 {/* <Link className="main-headline" href={`/${item.story.slug}`}>{item.story.headline}</Link>*/}
                </h2>
              <div styleName="section-time-wrapper" className="section-time-wrapper" >
                {/*<div>
                    <SectionTag story={item.story} />
                </div>*/}
                <div>
                    <TimeStamp timeStamp={item.story["published-at"]} />
                </div>
                <StarShowHide accessType={accessType} />
              </div>
              </Link>
            </React.Fragment>
          );
        })}
        <div>
        <ShowMoreBtn loadMore={handleLoadMore} btnName="NÄYTÄ LISÄÄ"/>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

NewestCategory.propTypes = {
  newestCategory: array,
  id: string,
};

export default NewestCategory;
