import React from 'react';
import assetify from "@quintype/framework/assetify";
import PerheuutisetBanner from  "../../../components/basic/images/kunsurukoskettaa.jpg"
import './perheuutiset-banner.m.css';

const Perheuutiset = () =>
  <div styleName="perheuutiset-banner">
        <a aria-label="Kun suru koskettaa" href="https://asiakaspalvelu.i-mediat.fi/jurvansanomat/ilmoita/"  target="_blank">
          <img src={`${assetify(PerheuutisetBanner)}`} alt="Kun suru koskettaa"  title="Kun suru koskettaa" />
        </a>
  </div>

export {Perheuutiset}
